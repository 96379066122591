import "./Main.css";
import companyImage from "./Assets/companyLogo.png";
import firstProduct from "./Assets/clinic.png";
import game2 from "./Assets/images/game1.png";
/*import game3 from "./Assets/images/game3.png";
import game4 from "./Assets/images/game4.png";*/
import React from "react";
import GamesList from "./components/GamesList";
import myGames from "./Assets/videos/My-games.mp4";
import { useMediaQuery } from 'react-responsive';

function Main() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <main className="main">
            {isDesktopOrLaptop && <section className="company-description">
                <article className="title">
                    <div className="title-com-desc">
                        <span className="title-com-name">
                            <h1 className="title-first-companyName">FX</h1>
                            <h1 className="title-second-companyName">Code</h1>
                        </span>
                        <div className="title-description">
                            <div>
                                We care about making websites, apps and games.</div>
                            <div>Feel free to contact us and enjoy our products.</div>
                        </div>
                        <a href="#contact" className="see-more">Contact us</a>
                    </div>
                    {isDesktopOrLaptop && <img className="company-img" src={companyImage} alt="company-img" />}
                    {isTabletOrMobile && <img className="company-img-mobile" src={companyImage} alt="company-img" />}
                </article>
            </section>}
            {isTabletOrMobile && <section className="company-description-mobile">
                <article className="title">
                    <div className="title-com-desc">
                        <h1 className="title-companyName">FX Code</h1>
                        <div className="title-description-mobile">
                            <div>We care about making websites, apps and games.</div>
                            <div>Feel free to contact us and enjoy our products.</div>
                        </div>
                        <a href="#contact" className="see-more">Contact us</a>
                    </div>
                    {isDesktopOrLaptop && <img className="company-img" src={companyImage} alt="company-img" />}
                    {isTabletOrMobile && <img className="company-img-mobile" src={companyImage} alt="company-img" />}
                </article>
            </section>}
            <h1>Our Products</h1>
            {isDesktopOrLaptop && <section className="company-games">
                <article className="games">
                    <img className="game-img" src={firstProduct} alt="game-img"></img>
                    <p>My Clinic: Get, add, delete, and edit Patients details.You can Get, add, delete, and
                        edit Sessions, Payments.</p>
                </article>
                <article className="games">
                    <img className="game-img-mobile" src={game2} alt="second-game-img"></img>
                    <p>Bounicing Orb: Is one of our games.You can  Handle the ball and collect stars and more.</p>
                </article>
                {/*<article className="games">
                    <img className="game-img" src={game2} alt="game-img"></img>
                    <p>Ballon: is a really nice game. Keep the balloon safe and don't forget the time.</p>
                </article>
                <article className="games">
                    <img className="game-img" src={game3} alt="game-img"></img><p>Monkey: nice game for kids. Help the monkey eating bananas and jumping safe.
                    </p>
                </article>
                <article className="games">
                    <img className="game-img-mobile" src={game4} alt="game-img"></img>
                    <p>Caveman: is an adventure game. Caveman hunts birds and collect apples.</p>
                </article>*/}
            </section>}
            {isTabletOrMobile && <section className="company-games-mobile">
                <article className="games-mobile">
                    <img className="game-img-mobile" src={firstProduct} alt="game-img"></img>
                    <p>Bounicing Orb: is one of our games. Handle the ball and collect stars.</p>
                </article>
                <article className="games-mobile">
                    <img className="game-img-mobile" src={game2} alt="game-img"></img>
                    <p>Bounicing Orb: is one of our games. Handle the ball and collect stars.</p>
                </article>
                {/*<article className="games-mobile">
                    <img className="game-img-mobile" src={game3} alt="game-img"></img><p>Monkey: nice game for kids. Help the monkey eating bananas and jumping safe.
                    </p>
                </article>
                <article className="games-mobile">
                    <img className="game-img-mobile" src={game4} alt="game-img"></img>
                    <p>Caveman: is an adventure game. Caveman hunts birds and collect apples.</p>
                </article>*/}
            </section>}
            {/*isDesktopOrLaptop && <section id="ga" className="games-video">
                <article>
                    <GamesList />
                </article>
            </section>*/}
            {/*isTabletOrMobile && <section id="ga" className="games-video-mobile">
                <article>
                    <div className="select-game">Select Game and watch the video</div>
                    <GamesList />
                </article>
            </section>*/}
            <section>
                {isDesktopOrLaptop && <article id="aboutus" className="aboutus">
                    <div>
                        We are glad to see you here.
                        4 years of success, year after year we are growing.
                    </div>
                </article>}
                {isTabletOrMobile && <article id="aboutus" className="aboutus-mobile">
                    <div>
                        <div>We are glad to see you here. 4 years </div>
                        <div>of success, year after year we are</div>
                        <div>growing.</div>
                    </div>
                </article>}
            </section>
        </main>
    );
}

export default Main;